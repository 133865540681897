import React, { useState } from 'react';

import { updateUserStatus, deleteUser } from '../services/api';

import '../styles/UserManagement.css';



function UserManagement({ users = [], onUserUpdated }) {

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');



  const handleStatusChange = async (userId, newStatus) => {

    setLoading(true);

    setError('');

    try {

      await updateUserStatus(userId, newStatus);

      if (onUserUpdated) {

        onUserUpdated();

      }

    } catch (err) {

      setError('Failed to update user status');

      console.error('Status update error:', err);

    } finally {

      setLoading(false);

    }

  };



  const handleDeleteUser = async (userId) => {

    if (!window.confirm('Are you sure you want to delete this user?')) {

      return;

    }



    setLoading(true);

    setError('');

    try {

      await deleteUser(userId);

      if (onUserUpdated) {

        onUserUpdated();

      }

    } catch (err) {

      setError('Failed to delete user');

      console.error('Delete user error:', err);

    } finally {

      setLoading(false);

    }

  };



  // Debug log to see what users data looks like

  console.log('UserManagement received users:', users);



  if (loading) {

    return <div className="loading-state">Loading users...</div>;

  }



  return (

    <div className="user-management">

      <h2>User Management</h2>

      

      {error && <div className="error-message">{error}</div>}

      

      {users.length === 0 ? (

        <p>No users found.</p>

      ) : (

        <table className="user-table">

          <thead>

            <tr>

              <th>Email</th>

              <th>Status</th>

              <th>Joined</th>

              <th>Actions</th>

            </tr>

          </thead>

          <tbody>

            {users.map(user => (

              <tr key={user.id || user.email}>

                <td>{user.email}</td>

                <td>{user.status || 'subscribed'}</td>

                <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'N/A'}</td>

                <td className="actions">

                  <select

                    value={user.status || 'subscribed'}

                    onChange={(e) => handleStatusChange(user.id, e.target.value)}

                    disabled={loading}

                  >

                    <option value="subscribed">Subscribed</option>

                    <option value="unsubscribed">Unsubscribed</option>

                  </select>

                  <button

                    onClick={() => handleDeleteUser(user.id)}

                    disabled={loading}

                    className="delete-button"

                  >

                    Delete

                  </button>

                </td>

              </tr>

            ))}

          </tbody>

        </table>

      )}

    </div>

  );

}



export default UserManagement; 
