import React, { useState, memo } from 'react';
import { subscribeUser } from '../services/api';
import logo from '../assets/logo.png';
import '../styles/SignupPage.css';
import CountdownTimer from '../components/CountdownTimer';

// Separate memoized form component
const SubscriptionForm = memo(({ onSubmit, isLoading, error }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <div className="form-container">
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
          className="email-input"
          autoComplete="email"
          spellCheck="false"
        />
        <button 
          type="submit" 
          disabled={isLoading}
          className="submit-button"
        >
          {isLoading ? 'JOINING...' : 'ACCEPT THE CHALLENGE'}
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <p className="signup-note">You're one step away from unlocking your potential.</p>
      <p className="privacy-note">Your privacy matters. Unsubscribe anytime.</p>
    </form>
  );
});

// Memoized success message component
const SuccessMessage = memo(() => (
  <div className="success-container">
    <div className="success-animation">
      <div className="checkmark">✓</div>
    </div>
    <h2 className="welcome-message">Welcome to the Momentum Family!</h2>
    <div className="next-steps">
      <div className="step">
        <div className="step-icon">📧</div>
        <p>Check your inbox for a welcome email</p>
      </div>
      <div className="step">
        <div className="step-icon">🎯</div>
        <p>Your first challenge arrives soon</p>
      </div>
      <div className="step">
        <div className="step-icon">🏅</div>
        <p>Get ready to earn your first badge</p>
      </div>
    </div>
    <CountdownTimer className="success-countdown" />
    <div className="social-share">
      <p>Share your commitment with others:</p>
      <div className="share-buttons">
        <button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent("I just joined Momentum's Elite Fitness Challenge! Ready to push my limits and earn some badges. 💪 Join me at momentumchallenges.com")}`, '_blank')} className="share-button twitter">
          Share on Twitter
        </button>
      </div>
    </div>
  </div>
));

function SignupPage() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = async (email) => {
    setIsLoading(true);
    setError('');

    try {
      const response = await subscribeUser(email);
      if (response.userHash) {
        localStorage.setItem('userHash', response.userHash);
      }
      setIsSubscribed(true);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to subscribe. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="animated-background">
        <div className="gradient-overlay"></div>
        <div className="geometric-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
      </div>
      
      <div className="signup-content">
        <div className="signup-header">
          <div className="logo-container">
            <img src={logo} alt="Momentum" className="logo-image" />
          </div>
          <h1>MOMENTUM</h1>
          <div className="tagline-container">
            <p className="tagline">Unlock Your</p>
            <p className="highlight">Peak Performance</p>
          </div>
          <p className="subtitle">
            Become Part of an Exclusive Community of Athletes Pushing Boundaries and Crushing Goals
          </p>
        </div>

        <div className="cta-section">
          {!isSubscribed && <CountdownTimer />}
          {isSubscribed ? (
            <SuccessMessage />
          ) : (
            <SubscriptionForm 
              onSubmit={handleSubmit}
              isLoading={isLoading}
              error={error}
            />
          )}
        </div>

        {!isSubscribed && (
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon workout">🏋️‍♂️</div>
              <h3>Progressive Challenges</h3>
              <p>Take on dynamic, weekly challenges designed to elevate your skills and test your limits</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon medal">🏅</div>
              <h3>Achievement Badges</h3>
              <p>Earn exclusive badges that symbolize your hard work, commitment, and progress</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon strength">💪</div>
              <h3>Elite Community</h3>
              <p>Connect with passionate athletes who, like you, are committed to growth and excellence</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon fire">🔥</div>
              <h3>Challenge Streaks</h3>
              <p>Build a winning streak by completing consecutive challenges! Stay consistent to unlock special rewards</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignupPage; 
