import React, { useState, useEffect, memo } from 'react';
import '../styles/CountdownTimer.css';
import { fetchNextChallenge } from '../services/api';
import moment from 'moment-timezone';

const CountdownTimer = memo(({ className }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [error, setError] = useState(null);
  const [challengeTime, setChallengeTime] = useState(null);

  // Fetch next challenge time on mount
  useEffect(() => {
    const fetchChallengeTime = async () => {
      try {
        const response = await fetchNextChallenge();
        if (!response.challenge) {
          setError('No upcoming challenges found');
          return;
        }
        setChallengeTime(moment(response.challenge.scheduledFor));
      } catch (err) {
        console.error('Error fetching next challenge:', err);
        setError('Failed to fetch next challenge');
      }
    };
    fetchChallengeTime();
  }, []);

  // Update timer
  useEffect(() => {
    if (!challengeTime) return;

    let mounted = true;
    
    const updateTimer = () => {
      if (!mounted) return;

      const now = moment();
      const difference = challengeTime.diff(now);
      
      if (difference <= 0) {
        setIsExpired(true);
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
        return;
      }

      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      });
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => {
      mounted = false;
      clearInterval(timer);
    };
  }, [challengeTime]);

  if (error) {
    return (
      <div className={`countdown-timer ${className || ''}`}>
        <div className="countdown-header">{error}</div>
      </div>
    );
  }

  if (!timeLeft) {
    return (
      <div className={`countdown-timer ${className || ''}`}>
        <div className="countdown-header">Loading next challenge...</div>
      </div>
    );
  }

  return (
    <div className={`countdown-timer ${className || ''}`}>
      <div className="countdown-header">
        {isExpired ? "Challenge Starting Now!" : "Next Challenge Starts In:"}
      </div>
      <div className="timer-display">
        <div className="time-segment">
          <span className="time-value">{String(timeLeft.days).padStart(2, '0')}</span>
          <span className="time-label">Days</span>
        </div>
        <span className="time-separator">:</span>
        <div className="time-segment">
          <span className="time-value">{String(timeLeft.hours).padStart(2, '0')}</span>
          <span className="time-label">Hours</span>
        </div>
        <span className="time-separator">:</span>
        <div className="time-segment">
          <span className="time-value">{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span className="time-label">Minutes</span>
        </div>
        <span className="time-separator">:</span>
        <div className="time-segment">
          <span className="time-value">{String(timeLeft.seconds).padStart(2, '0')}</span>
          <span className="time-label">Seconds</span>
        </div>
      </div>
    </div>
  );
});

export default CountdownTimer;