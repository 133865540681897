import React from 'react';
import ErrorBoundary from './components/ErrorBoundary';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import SignupPage from './pages/SignupPage';

import UnsubscribePage from './pages/UnsubscribePage';

import AdminLoginPage from './pages/AdminLoginPage';

import AdminPage from './pages/AdminPage';

import ChallengeManager from './components/ChallengeManager';

import BadgeManager from './components/BadgeManager';

import AdminNav from './components/AdminNav';

import UserManagement from './components/UserManagement';

import ClaimBadgePage from './pages/ClaimBadgePage';

import CountdownTimer from './components/CountdownTimer';

import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

import BadgeVaultPage from './pages/BadgeVaultPage';

import './App.css';

function AdminLayout({ children }) {
  const isAuthenticated = !!localStorage.getItem('adminToken');

  if (!isAuthenticated) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className="admin-layout">
      <AdminNav />
      <div className="admin-content">
        {children}
      </div>
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<SignupPage />} />
            <Route path="/unsubscribe" element={<UnsubscribePage />} />
            <Route path="/admin" element={<AdminLoginPage />} />
            <Route path="/admin/dashboard" element={
              <AdminLayout>
                <AdminPage />
              </AdminLayout>
            } />
            <Route path="/admin/challenges" element={
              <AdminLayout>
                <ChallengeManager />
              </AdminLayout>
            } />
            <Route path="/admin/badges" element={
              <AdminLayout>
                <BadgeManager />
              </AdminLayout>
            } />
            <Route path="/admin/users" element={
              <AdminLayout>
                <UserManagement />
              </AdminLayout>
            } />
            <Route path="/claim/:challengeId/:difficulty/:userHash" 
                   element={<Navigate to={location => {
                     const {challengeId, difficulty, userHash} = location.params;
                     return `/claim-badge/${challengeId}/${difficulty}?userHash=${userHash}`;
                   }}/>} 
            />
            <Route path="/claim-badge/:challengeId/:difficulty" element={<ClaimBadgePage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/badge-vault" element={<BadgeVaultPage />} />
            <Route path="/badge-vault/:userHash" 
                   element={<Navigate to={location => {
                     const {userHash} = location.params;
                     return `/badge-vault?userHash=${userHash}`;
                   }}/>} 
            />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App; 


