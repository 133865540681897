import React from 'react';



import '../styles/PrivacyPolicyPage.css';







function PrivacyPolicyPage() {



  return (



    <div className="privacy-page">



      <div className="animated-background">



        <div className="gradient-overlay"></div>



      </div>



      <div className="privacy-container">



        <h1>Privacy Policy</h1>



        <p className="last-updated">Last Updated: March 2024</p>







        <section className="policy-section">



          <h2>1. Introduction</h2>



          <p>



            Welcome to Momentum Fitness Challenges. We respect your privacy and are committed to protecting your personal data. 



            This privacy policy explains how we collect, use, and safeguard your information when you use our service.



          </p>



        </section>







        <section className="policy-section">



          <h2>2. Information We Collect</h2>



          <h3>2.1 Personal Information</h3>



          <p>We collect the following personal information:</p>



          <ul>



            <li>Email address</li>



            <li>Challenge completion history</li>



            <li>Achievement badges earned</li>



            <li>Challenge participation statistics</li>



          </ul>







          <h3>2.2 Usage Information</h3>



          <p>We automatically collect certain information when you use our service:</p>



          <ul>



            <li>IP address</li>



            <li>Browser type and version</li>



            <li>Device information</li>



            <li>Access times and dates</li>



            <li>Pages viewed</li>



          </ul>



        </section>







        <section className="policy-section">



          <h2>3. How We Use Your Information</h2>



          <p>We use your information for the following purposes:</p>



          <ul>



            <li>To provide and maintain our service</li>



            <li>To notify you about upcoming challenges</li>



            <li>To track your momentum and achievements</li>



            <li>To send you essential service updates</li>



            <li>To improve our service based on your feedback</li>



            <li>To prevent fraud and abuse</li>



          </ul>



        </section>







        <section className="policy-section">



          <h2>4. Information Sharing</h2>



          <p>We do not sell, trade, or rent your personal information to third parties. We may share your information in the following circumstances:</p>



          <ul>



            <li>With your consent</li>



            <li>To comply with legal obligations</li>



            <li>To protect our rights and safety</li>



            <li>With service providers who assist in our operations</li>



          </ul>



        </section>







        <section className="policy-section">



          <h2>5. Data Security</h2>



          <p>



            We implement appropriate security measures to protect your personal information. These measures include:



          </p>



          <ul>



            <li>Encryption of data in transit and at rest</li>



            <li>Regular security assessments</li>



            <li>Access controls and authentication</li>



            <li>Secure data storage practices</li>



          </ul>



        </section>







        <section className="policy-section">



          <h2>6. Your Rights</h2>



          <p>You have the following rights regarding your personal information:</p>



          <ul>



            <li>Right to access your data</li>



            <li>Right to correct inaccurate data</li>



            <li>Right to delete your data</li>



            <li>Right to withdraw consent</li>



            <li>Right to object to processing</li>



            <li>Right to data portability</li>



          </ul>



        </section>







        <section className="policy-section">



          <h2>7. Cookies</h2>



          <p>



            We use cookies to enhance your experience on our website. You can control cookie settings through your browser. 



            Essential cookies required for basic functionality cannot be disabled.



          </p>



        </section>







        <section className="policy-section">



          <h2>8. Children's Privacy</h2>



          <p>



            Our service is not intended for users under 13 years of age. We do not knowingly collect or maintain information 



            from persons under 13 years of age.



          </p>



        </section>







        <section className="policy-section">



          <h2>9. Changes to This Policy</h2>



          <p>



            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy 



            on this page and updating the "Last Updated" date.



          </p>



        </section>







        <section className="policy-section">



          <h2>10. Contact Us</h2>



          <p>



            If you have any questions about this Privacy Policy, please contact us at:



          </p>



          <ul>



            <li>Email: privacy@momentumchallenges.com</li>



            <li>Website: https://momentumchallenges.com</li>



          </ul>



        </section>



      </div>



    </div>



  );



}







export default PrivacyPolicyPage; 


