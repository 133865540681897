import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import ChallengeManager from '../components/ChallengeManager';
import BadgeManager from '../components/BadgeManager';
import { fetchUsers, fetchChallenges } from '../services/api';
import TestEmail from '../components/TestEmail';
import DashboardContent from '../components/DashboardContent';
import UserManagement from '../components/UserManagement';
import ChallengeList from '../components/ChallengeList';
import '../styles/AdminPage.css';
import '../styles/ErrorBoundary.css';

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('challenges');
  const navigate = useNavigate();

  const loadData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch users and challenges in parallel
      const [usersData, challengesData] = await Promise.all([
        fetchUsers(),
        fetchChallenges()
      ]);

      console.log('Fetched users:', usersData); // Debug log

      setUsers(Array.isArray(usersData) ? usersData : []);
      setChallenges(Array.isArray(challengesData) ? challengesData : []);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if admin is logged in
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin');
      return;
    }

    loadData();
  }, [navigate]);

  const renderContent = () => {
    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    switch (activeTab) {
      case 'dashboard':
        return <DashboardContent users={users} challenges={challenges} />;
      case 'challenges':
        return (
          <>
            <ChallengeManager onChallengeCreated={loadData} />
            <ChallengeList 
              challenges={challenges} 
              onChallengeDeleted={loadData}
            />
          </>
        );
      case 'badges':
        return <BadgeManager />;
      case 'users':
        return (
          <UserManagement 
            users={users} 
            onUserUpdated={loadData}
          />
        );
      case 'test-email':
        return <TestEmail challenges={challenges} />;
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary>
      <div className="admin-page">
        <div className="tab-buttons">
          <button
            className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
            onClick={() => setActiveTab('dashboard')}
          >
            Dashboard
          </button>
          <button
            className={`tab-button ${activeTab === 'challenges' ? 'active' : ''}`}
            onClick={() => setActiveTab('challenges')}
          >
            Challenges
          </button>
          <button
            className={`tab-button ${activeTab === 'badges' ? 'active' : ''}`}
            onClick={() => setActiveTab('badges')}
          >
            Badges
          </button>
          <button
            className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            Users
          </button>
          <button
            className={`tab-button ${activeTab === 'test-email' ? 'active' : ''}`}
            onClick={() => setActiveTab('test-email')}
          >
            Test Email
          </button>
        </div>

        <div className="tab-content">
          {renderContent()}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default AdminPage; 


