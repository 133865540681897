import React, { useState, useEffect } from 'react';
import '../styles/DashboardContent.css';

function DashboardContent({ users, challenges }) {
  const [recentActivity, setRecentActivity] = useState([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalChallenges: 0,
    completionRate: 0,
  });

  useEffect(() => {
    let mounted = true;

    const updateStats = () => {
      if (!mounted) return;
      // Calculate dashboard stats
      const activeUsers = users.filter(u => u.status === 'subscribed').length;
      
      setStats({
        totalUsers: users.length,
        activeUsers,
        totalChallenges: challenges.length,
        completionRate: users.length ? Math.round((activeUsers / users.length) * 100) : 0
      });

      // Generate recent activity (last 5 events)
      const activity = [
        ...users.map(user => ({
          type: 'signup',
          date: new Date(user.createdAt),
          details: `New user joined: ${user.email}`
        })),
        ...challenges.map(challenge => ({
          type: 'challenge',
          date: new Date(challenge.scheduledFor),
          details: `New challenge scheduled: ${challenge.title}`
        }))
      ]
      .sort((a, b) => b.date - a.date)
      .slice(0, 5);

      setRecentActivity(activity);
    };

    updateStats();
    return () => {
      mounted = false;
    };
  }, [users, challenges]);

  return (
    <div className="dashboard-content">
      <div className="dashboard-grid">
        <div className="dashboard-card">
          <h3>Total Users</h3>
          <p className="stat-number">{stats.totalUsers}</p>
          <p className="stat-label">registered users</p>
        </div>
        <div className="dashboard-card">
          <h3>Active Subscribers</h3>
          <p className="stat-number">{stats.activeUsers}</p>
          <p className="stat-label">current subscribers</p>
        </div>
        <div className="dashboard-card">
          <h3>Total Challenges</h3>
          <p className="stat-number">{stats.totalChallenges}</p>
          <p className="stat-label">challenges created</p>
        </div>
        <div className="dashboard-card">
          <h3>Retention Rate</h3>
          <p className="stat-number">{stats.completionRate}%</p>
          <p className="stat-label">active users</p>
        </div>
      </div>

      <div className="recent-activity">
        <h2>Recent Activity</h2>
        <div className="activity-list">
          {recentActivity.map((activity, index) => (
            <div key={index} className="activity-item">
              <div className={`activity-icon ${activity.type}`}>
                {activity.type === 'signup' ? '👤' : '🎯'}
              </div>
              <div className="activity-details">
                <p>{activity.details}</p>
                <span className="activity-date">
                  {activity.date.toLocaleDateString()} at {activity.date.toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardContent; 