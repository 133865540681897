import axios from 'axios';



const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';



console.log('API URL:', API_URL);



const api = axios.create({

  baseURL: API_URL,

  headers: {

    'Content-Type': 'application/json'

  }

});



// Add auth token to requests if it exists

api.interceptors.request.use(config => {

  const token = localStorage.getItem('adminToken');

  if (token) {

    config.headers.Authorization = `Bearer ${token}`;

  }

  return config;

});



// Helper function to get token

const getToken = () => localStorage.getItem('adminToken');



// User Management

export const fetchUsers = async () => {

  const response = await api.get('/api/admin/users');

  return response.data;

};



export const subscribeUser = async (email) => {

  try {

    const response = await api.post('/api/subscribe', { email });

    if (response.data.userHash) {

      localStorage.setItem('userHash', response.data.userHash);

    }

    return response.data;

  } catch (error) {

    console.error('Subscription error:', error);

    throw error;

  }

};



export const unsubscribeUser = async (email, userHash) => {

  try {

    const response = await api.post('/api/unsubscribe', { 

      email,

      userHash

    });

    return response.data;

  } catch (error) {

    console.error('Unsubscribe error:', error);

    throw error;

  }

};



// Badge Management

export const createBadge = async (formData) => {

  const response = await api.post('/api/admin/badges', formData, {

    headers: {

      'Content-Type': 'multipart/form-data'

    }

  });

  return response.data;

};



export const fetchBadges = async () => {

  const response = await api.get('/api/admin/badges');

  return response.data;

};



// Challenge Management

export const createChallenge = async (formData) => {

  try {

    const response = await api.post('/api/admin/challenges', formData, {

      headers: {

        'Content-Type': 'multipart/form-data',

      },

      timeout: 30000, // 30 seconds

      maxContentLength: 10 * 1024 * 1024, // 10MB max size

    });

    return response.data;

  } catch (error) {

    console.error('Challenge creation error:', error.response?.data || error);

    throw error;

  }

};



export const fetchChallenges = async () => {

  const response = await api.get('/api/admin/challenges');

  return response.data;

};



// Challenge Completion

export const claimBadge = async (challengeId, difficulty) => {

  try {

    const userHash = localStorage.getItem('userHash');

    if (!userHash) {

      throw new Error('User hash not found');

    }

    const response = await api.post(

      `/api/challenges/${challengeId}/claim-badge/${difficulty}`,

      { userHash }

    );

    return response.data;

  } catch (error) {

    console.error('Badge claim error:', error);

    throw error;

  }

};



// Admin Authentication

export const loginAdmin = async (username, password) => {

  const response = await api.post('/api/admin/login', { username, password });

  if (response.data.token) {

    localStorage.setItem('adminToken', response.data.token);

  }

  return response.data;

};



export const logoutAdmin = () => {

  localStorage.removeItem('adminToken');

};



export const fetchUserStats = async () => {

  try {

    const response = await api.get('/api/user/stats');

    return response.data;

  } catch (error) {

    console.error('Error fetching user stats:', error);

    return {

      currentStreak: 0,

      longestStreak: 0,

      bronzeTotal: 0,

      silverTotal: 0,

      goldTotal: 0

    };

  }

};



export const updateUserStatus = async (userId, status) => {

  const response = await api.put(`/api/admin/users/${userId}`, { status });

  return response.data;

};



export const deleteUser = async (userId) => {

  const response = await api.delete(`/api/admin/users/${userId}`);

  return response.data;

};



export const deleteChallenge = async (challengeId) => {

  const response = await api.delete(`/api/admin/challenges/${challengeId}`);

  return response.data;

};



export const sendTestEmail = async (email, challengeId) => {

  try {

    const response = await api.post('/api/admin/test-email', {

      email,

      challengeId

    });

    if (!response.data) {

      throw new Error('No response from server');

    }

    return response.data;

  } catch (error) {

    console.error('Test email error:', error.response?.data || error);

    throw new Error(error.response?.data?.error || 'Failed to send test email');

  }

};



export const getNextChallenge = async () => {

  try {

    console.log('Fetching next challenge...');

    const response = await api.get('/api/challenges/next');

    console.log('Next challenge response:', response.data);

    return response.data;

  } catch (error) {

    console.error('Error fetching next challenge:', error);

    if (error.response?.status === 404) {

      return { status: 'no_challenges', message: 'No upcoming challenges scheduled' };

    }

    throw error;

  }

};



export const getUsers = async () => {

  try {

    const response = await api.get('/api/admin/users');

    return response.data;

  } catch (error) {

    console.error('Failed to fetch users:', error);

    throw error;

  }

};



// Make sure the error interceptor is properly handling 401s

api.interceptors.response.use(

  response => response,

  error => {

    if (error.response?.status === 401) {

      // Clear admin token and redirect to login

      localStorage.removeItem('adminToken');

      window.location.href = '/admin/login';

    }

    return Promise.reject(error);

  }

);



export const fetchChallengeBadge = async (challengeId, difficulty, userHash) => {

  try {

    const response = await api.post(`/api/challenges/${challengeId}/claim-badge/${difficulty}`, {

      userHash

    });

    return response.data;

  } catch (error) {

    if (error.response?.status === 400) {

      throw new Error(error.response.data.error);

    }

    throw new Error('Failed to claim badge. Please try again.');

  }

};



export const fetchBadgeVault = async (userHash) => {

  try {

    const response = await api.get(`/api/challenges/badge-vault/${encodeURIComponent(userHash)}`);

    console.log('API Response:', response.data); // Debug log

    return response.data;

  } catch (error) {

    console.error('API Error:', error.response || error); // Debug log

    throw error;

  }

};



export const fetchNextChallenge = async () => {

  try {

    const response = await api.get('/api/challenges/next');

    return response.data;

  } catch (error) {

    console.error('Error fetching next challenge:', error);

    throw error;

  }

};



export const getBadgesByHash = async (userHash) => {

  try {

    const response = await api.get(`/api/badges/${userHash}`);

    return response.data;

  } catch (error) {

    throw error;

  }

};



export default api; 






























































