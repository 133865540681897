import React, { useState } from 'react';
import { sendTestEmail } from '../services/api';
import '../styles/TestEmail.css';

function TestEmail({ challenges }) {
  const [email, setEmail] = useState('');
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      console.log('Sending test email:', { email, challengeId: selectedChallenge });
      await sendTestEmail(email, selectedChallenge);
      setMessage('Test email sent successfully!');
      setEmail('');
      setSelectedChallenge('');
    } catch (err) {
      console.error('Test email error:', err);
      setError(err.message || 'Failed to send test email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="test-email">
      <h2>Send Test Email</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter email address"
          />
        </div>

        <div className="form-group">
          <label>Select Challenge</label>
          <select
            value={selectedChallenge}
            onChange={(e) => setSelectedChallenge(e.target.value)}
            required
          >
            <option value="">Select a challenge</option>
            {challenges?.map(challenge => (
              <option key={challenge.id} value={challenge.id}>
                {challenge.title} ({new Date(challenge.scheduledFor).toLocaleDateString()})
              </option>
            ))}
          </select>
        </div>

        {error && <div className="error">{error}</div>}
        {message && <div className="success">{message}</div>}

        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Test Email'}
        </button>
      </form>
    </div>
  );
}

export default TestEmail; 






























