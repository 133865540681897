import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { unsubscribeUser } from '../services/api';

function UnsubscribePage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Get email and hash from URL parameters
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const handleUnsubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const params = new URLSearchParams(location.search);
      const hash = params.get('hash');
      
      if (!hash) {
        setError('Invalid unsubscribe link. Please use the link provided in your email.');
        setLoading(false);
        return;
      }
      
      await unsubscribeUser(email, hash);
      setMessage('You have been successfully unsubscribed from Momentum emails.');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to unsubscribe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="unsubscribe-page">
      <h1>Unsubscribe from Momentum</h1>
      {message ? (
        <div className="success-message">{message}</div>
      ) : (
        <form onSubmit={handleUnsubscribe}>
          <p>Please confirm your email address to unsubscribe:</p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Confirm Unsubscribe'}
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      )}
    </div>
  );
}

export default UnsubscribePage; 