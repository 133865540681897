import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/AdminNav.css';

function AdminNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin');
  };

  return (
    <div className="admin-nav">
      <div className="nav-header">
        <h1>MOMENTUM</h1>
        <p className="nav-subtitle">Admin Panel</p>
      </div>

      <nav className="nav-links">
        <button 
          className={`nav-link ${currentPath === '/admin/dashboard' ? 'active' : ''}`}
          onClick={() => navigate('/admin/dashboard')}
        >
          📊 Management
        </button>
      </nav>

      <button onClick={handleLogout} className="logout-button">
        Sign Out
      </button>
    </div>
  );
}

export default AdminNav; 