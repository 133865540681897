import React, { useState, useEffect } from 'react';
import { createChallenge } from '../services/api';
import '../styles/ChallengeManager.css';

function ChallengeManager({ onChallengeCreated }) {
  const [title, setTitle] = useState('');
  const [bronzeDescription, setBronzeDescription] = useState('');
  const [silverDescription, setSilverDescription] = useState('');
  const [goldDescription, setGoldDescription] = useState('');
  const [scheduledFor, setScheduledFor] = useState('');
  const [bronzeBadge, setBronzeBadge] = useState(null);
  const [silverBadge, setSilverBadge] = useState(null);
  const [goldBadge, setGoldBadge] = useState(null);
  const [challengeImage, setChallengeImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [challengeImagePreview, setChallengeImagePreview] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    const formData = new FormData();
    formData.append('title', title);
    formData.append('bronzeDescription', bronzeDescription);
    formData.append('silverDescription', silverDescription);
    formData.append('goldDescription', goldDescription);
    formData.append('scheduledFor', scheduledFor);

    if (bronzeBadge) formData.append('bronzeBadge', bronzeBadge);
    if (silverBadge) formData.append('silverBadge', silverBadge);
    if (goldBadge) formData.append('goldBadge', goldBadge);
    if (challengeImage) formData.append('challengeImage', challengeImage);

    try {
      await createChallenge(formData);
      setMessage('Challenge created successfully!');
      // Reset form
      setTitle('');
      setBronzeDescription('');
      setSilverDescription('');
      setGoldDescription('');
      setScheduledFor('');
      setBronzeBadge(null);
      setSilverBadge(null);
      setGoldBadge(null);
      setChallengeImage(null);
      if (onChallengeCreated) onChallengeCreated();
    } catch (err) {
      setError(err.message || 'Failed to create challenge');
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setChallengeImage(file);
    
    // Create preview URL
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setChallengeImagePreview(previewUrl);
    } else {
      setChallengeImagePreview(null);
    }
  };

  // Cleanup preview URL on unmount
  useEffect(() => {
    return () => {
      if (challengeImagePreview) {
        URL.revokeObjectURL(challengeImagePreview);
      }
    };
  }, [challengeImagePreview]);

  return (
    <div className="challenge-manager">
      <h2>Create New Challenge</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Challenge Image (Optional)</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <small>This image will be shown in the challenge email</small>
          
          {challengeImagePreview && (
            <div className="image-preview">
              <img 
                src={challengeImagePreview} 
                alt="Challenge preview" 
                className="challenge-image-preview"
              />
            </div>
          )}
        </div>

        <div className="difficulty-section">
          <h3>Bronze Level</h3>
          <div className="form-group">
            <label>Description</label>
            <textarea
              value={bronzeDescription}
              onChange={(e) => setBronzeDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Badge Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setBronzeBadge(e.target.files[0])}
              required
            />
          </div>
        </div>

        <div className="difficulty-section">
          <h3>Silver Level</h3>
          <div className="form-group">
            <label>Description</label>
            <textarea
              value={silverDescription}
              onChange={(e) => setSilverDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Badge Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setSilverBadge(e.target.files[0])}
              required
            />
          </div>
        </div>

        <div className="difficulty-section">
          <h3>Gold Level</h3>
          <div className="form-group">
            <label>Description</label>
            <textarea
              value={goldDescription}
              onChange={(e) => setGoldDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Badge Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setGoldBadge(e.target.files[0])}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Scheduled For</label>
          <input
            type="datetime-local"
            value={scheduledFor}
            onChange={(e) => setScheduledFor(e.target.value)}
            required
          />
        </div>

        {error && <div className="error">{error}</div>}
        {message && <div className="success">{message}</div>}
        
        <button type="submit" disabled={loading}>
          {loading ? 'Creating...' : 'Create Challenge'}
        </button>
      </form>
    </div>
  );
}

export default ChallengeManager; 