import React from 'react';
import { deleteChallenge } from '../services/api';
import '../styles/ChallengeList.css';

function ChallengeList({ challenges, onChallengeDeleted }) {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDelete = async (challengeId) => {
    if (window.confirm('Are you sure you want to delete this challenge?')) {
      try {
        await deleteChallenge(challengeId);
        if (onChallengeDeleted) {
          onChallengeDeleted();
        }
      } catch (error) {
        console.error('Failed to delete challenge:', error);
        alert('Failed to delete challenge. Please try again.');
      }
    }
  };

  // Sort challenges by scheduledFor date
  const sortedChallenges = [...challenges].sort((a, b) => 
    new Date(a.scheduledFor) - new Date(b.scheduledFor)
  );

  return (
    <div className="challenge-list">
      <h2>Upcoming Challenges</h2>
      {sortedChallenges.length === 0 ? (
        <p className="no-challenges">No challenges scheduled yet.</p>
      ) : (
        <div className="challenges-grid">
          {sortedChallenges.map(challenge => (
            <div key={challenge.id} className="challenge-card">
              <div className="challenge-card-header">
                <h3>{challenge.title}</h3>
                <button 
                  onClick={() => handleDelete(challenge.id)}
                  className="delete-button"
                  title="Delete Challenge"
                >
                  ×
                </button>
              </div>
              {challenge.imageUrl && (
                <div className="challenge-image-container">
                  <img 
                    src={challenge.imageUrl} 
                    alt={challenge.title}
                    className="challenge-preview-image"
                  />
                </div>
              )}
              <p className="scheduled-date">
                Scheduled for: {formatDate(challenge.scheduledFor)}
              </p>
              <p className="description">
                Bronze: {challenge.bronzeDescription}<br/>
                Silver: {challenge.silverDescription}<br/>
                Gold: {challenge.goldDescription}
              </p>
              <div className="badge-previews">
                {challenge.bronzeBadge && (
                  <div className="badge-preview">
                    <img src={challenge.bronzeBadge.imageUrl} alt="Bronze Badge" />
                    <span>Bronze</span>
                  </div>
                )}
                {challenge.silverBadge && (
                  <div className="badge-preview">
                    <img src={challenge.silverBadge.imageUrl} alt="Silver Badge" />
                    <span>Silver</span>
                  </div>
                )}
                {challenge.goldBadge && (
                  <div className="badge-preview">
                    <img src={challenge.goldBadge.imageUrl} alt="Gold Badge" />
                    <span>Gold</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ChallengeList; 