import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { fetchChallengeBadge, fetchUserStats } from '../services/api';
import '../styles/ClaimBadgePage.css';
import { FaTwitter, FaFacebook, FaDownload, FaShare } from 'react-icons/fa';
import Confetti from 'react-confetti';

function ClaimBadgePage() {
  const { challengeId, difficulty } = useParams();
  const location = useLocation();
  const [badge, setBadge] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [userStats, setUserStats] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Get userHash from URL params or localStorage
  const getUserHash = () => {
    // First try URL parameters
    const params = new URLSearchParams(location.search);
    const urlUserHash = params.get('userHash');
    
    if (urlUserHash) {
      localStorage.setItem('userHash', urlUserHash);
      return urlUserHash;
    }
    
    // Fall back to localStorage
    return localStorage.getItem('userHash');
  };

  useEffect(() => {
    const loadBadge = async () => {
      try {
        const userHash = getUserHash();
        
        if (!challengeId || !difficulty || !userHash) {
          setError({
            title: 'Invalid Link',
            message: 'Please check your email for the correct badge claim link.',
            actionText: 'Return Home',
            actionLink: '/'
          });
          setLoading(false);
          return;
        }

        setLoading(true);
        const badgeData = await fetchChallengeBadge(challengeId, difficulty, userHash);
        
        if (badgeData.alreadyClaimed) {
          setError({
            title: 'Badge Already Claimed',
            message: `You've already earned the ${badgeData.claimedDifficulty} badge for this challenge! Keep pushing for the next one! 💪`,
            actionText: 'View Badge Vault',
            actionLink: `/badge-vault?userHash=${encodeURIComponent(userHash)}`
          });
          return;
        }

        if (!badgeData?.badge) {
          throw new Error('Invalid badge data received');
        }

        setBadge(badgeData.badge);
        setChallenge(badgeData.challenge);
        setUserStats(badgeData.stats);
        setShowConfetti(true);
      } catch (err) {
        console.error('Failed to load badge:', err);
        const errorMessage = err.message || 'Failed to load badge information.';
        setError({
          title: 'Oops! Something went wrong',
          message: errorMessage,
          actionText: errorMessage.includes('already claimed') ? 'View Badge Vault' : 'Try Again',
          actionLink: errorMessage.includes('already claimed') ? 
            `/badge-vault?userHash=${encodeURIComponent(getUserHash())}` : 
            null
        });
      } finally {
        setLoading(false);
      }
    };
    
    loadBadge();
  }, [challengeId, difficulty, location.search]);

  useEffect(() => {
    if (badge?.imageUrl) {
      // Update Open Graph meta tags when badge is loaded
      document.querySelector('meta[property="og:title"]').setAttribute('content', `I earned a ${difficulty} badge in Momentum!`);
      document.querySelector('meta[property="og:image"]').setAttribute('content', badge.imageUrl);
      document.querySelector('meta[name="twitter:title"]').setAttribute('content', `I earned a ${difficulty} badge in Momentum!`);
      document.querySelector('meta[name="twitter:image"]').setAttribute('content', badge.imageUrl);
    }
  }, [badge, difficulty]);

  const handleShare = async (platform) => {
    if (!badge?.imageUrl) return;
    
    const baseUrl = 'https://www.momentumchallenges.com';
    const badgeVaultUrl = `${baseUrl}/badge-vault?userHash=${encodeURIComponent(getUserHash())}`;
    const text = `I just earned a ${difficulty} badge in the Momentum Challenge! 🏅`;
    const hashtags = 'MomentumChallenges,FitnessChallenge';

    if (platform === 'generic') {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Momentum Badge',
            text: text,
            url: badgeVaultUrl
          });
        } catch (err) {
          if (err.name !== 'AbortError') {
            await navigator.clipboard.writeText(`${text}\n${badgeVaultUrl}`);
            alert('Share text copied to clipboard!');
          }
        }
        return;
      } else {
        await navigator.clipboard.writeText(`${text}\n${badgeVaultUrl}`);
        alert('Share text copied to clipboard!');
        return;
      }
    }

    let shareUrl;
    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&hashtags=${hashtags}&url=${encodeURIComponent(badgeVaultUrl)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/dialog/share?app_id=1107643947701312&display=popup&href=${encodeURIComponent(badgeVaultUrl)}&quote=${encodeURIComponent(text)}&hashtag=%23MomentumChallenges`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
  };

  const handleDownload = async () => {
    if (!badge?.imageUrl) {
      setError('Badge image not available');
      return;
    }
    
    try {
      const response = await fetch(badge.imageUrl);
      if (!response.ok) throw new Error('Failed to fetch image');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      
      // Include challenge title and difficulty in the filename
      const safeTitle = challenge?.title?.replace(/[^a-z0-9]/gi, '-').toLowerCase() || 'momentum';
      link.download = `momentum-${safeTitle}-${difficulty}-badge.png`;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download badge:', error);
      setError('Failed to download badge. Please try again.');
    }
  };

  // Update the badge vault link to use the correct URL structure
  const getBadgeVaultUrl = () => {
    const userHash = getUserHash();
    return `/badge-vault?userHash=${encodeURIComponent(userHash)}`;
  };

  if (loading) return (
    <div className="claim-page">
      <div className="claim-container">
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading your badge...</p>
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="claim-page">
      <div className="claim-container">
        <div className="error-state">
          <h2>{error.title}</h2>
          <p>{error.message}</p>
          {error.actionLink ? (
            <Link to={error.actionLink} className="primary-button">
              {error.actionText}
            </Link>
          ) : (
            <button 
              onClick={() => window.location.reload()} 
              className="primary-button"
            >
              {error.actionText}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  if (!badge) return <div className="error-message">Badge not found</div>;

  return (
    <div className="claim-page">
      <div className="animated-background">
        <div className="gradient-overlay"></div>
        <div className="geometric-shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
      </div>

      <div className="claim-container">
        <div className="badge-showcase">
          <h1 className="celebration-title">Congratulations!</h1>
          <div className="badge-wrapper">
            <img src={badge?.imageUrl} alt="Challenge Badge" className="badge-image" />
            <div className="badge-glow"></div>
          </div>
          <p className="achievement-text">You've earned the {difficulty} badge!</p>
          <p className="badge-description">
            {challenge?.[`${difficulty}Description`]}
          </p>
          
          <br/>
          <Link to={getBadgeVaultUrl()} className="primary-button" style={{textDecoration: 'none'}}>
            🏦View it in the Badge Vault ➡️ 
          </Link>
        </div>

        <div className="user-progress">
          <center><h2>Your Momentum</h2></center>
          <br/>

          <div className="stats-grid">
            <div className="stat-card">
              <span className="stat-icon">🔥</span>
              <span className="stat-value">{userStats?.currentStreak || 0}</span>
              <span className="stat-label">Current Streak</span>
            </div>
            <div className="stat-card">
              <span className="stat-icon">⭐</span>
              <span className="stat-value">{userStats?.longestStreak || 0}</span>
              <span className="stat-label">Best Streak</span>
            </div>
          </div>

          <div className="medals-grid">
            <div className="medal-stat gold">
              <span className="medal-icon">🥇</span>
              <span className="medal-count">{userStats?.goldTotal || 0}</span>
              <span className="medal-label">Gold</span>
            </div>
            <div className="medal-stat silver">
              <span className="medal-icon">🥈</span>
              <span className="medal-count">{userStats?.silverTotal || 0}</span>
              <span className="medal-label">Silver</span>
            </div>
            <div className="medal-stat bronze">
              <span className="medal-icon">🥉</span>
              <span className="medal-count">{userStats?.bronzeTotal || 0}</span>
              <span className="medal-label">Bronze</span>
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <button onClick={handleDownload} className="primary-button download-button">
            <FaDownload /> Download Badge
          </button>
                    
          <div className="share-buttons">
            <button onClick={() => handleShare('twitter')} className="share-button twitter">
              <FaTwitter /> Share on Twitter
            </button>
            <button onClick={() => handleShare('facebook')} className="share-button facebook">
              <FaFacebook /> Share on Facebook
            </button>
            <button onClick={() => handleShare('general')} className="share-button share">
              <FaShare /> Share
            </button>
          </div>
        </div>

        <div className="next-challenge">
          <h2>Ready for Your Next Challenge?</h2>
          <p>Keep the momentum going! Check your email for the next exciting challenge.</p>
        </div>
      </div>

      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={200}
          recycle={false}
          colors={['#FFD700', '#C0C0C0', '#CD7F32', '#4F46E5']}
        />
      )}
    </div>
  );
}

export default ClaimBadgePage;


