import React, { useState } from 'react';
import { createBadge } from '../services/api';
import '../styles/BadgeManager.css';

function BadgeManager() {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);

      await createBadge(formData);
      setMessage('Badge created successfully!');
      setName('');
      setImage(null);
      setImagePreview('');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to create badge');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="badge-manager">
      <h2>Create New Badge</h2>
      <form onSubmit={handleSubmit} className="badge-form">
        <div className="form-group">
          <label>Badge Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter badge name"
            required
          />
        </div>

        <div className="form-group">
          <label>Badge Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            required
            className="file-input"
          />
          {imagePreview && (
            <div className="image-preview">
              <img src={imagePreview} alt="Preview" />
            </div>
          )}
        </div>

        <button type="submit" disabled={loading} className="submit-button">
          {loading ? 'Creating...' : 'Create Badge'}
        </button>
      </form>

      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default BadgeManager; 